.Components {
  // padding: 50px 40px;
  padding: 50px 0;
  overflow: hidden;
  align-items: center;
  background: #eef0f5;
  border-radius: 24px;
  margin: 30px 50px;
  z-index: 0;

  @media (max-width: 1023px) {
    margin: 15px 40px;
    padding: 50px 0;
  }

  @media (max-width: 767px) {
    margin: 15px 15px;
    padding: 50px 0 30px;
  }

  @media (max-width: 767px) {
    &__items {
      z-index: 2;
      position: relative;
      width: 300px;
    }
  }

  &__title {
    text-align: left;
    max-width: 650px;
    margin: 0 0 50px 0;

    @media (max-width: 1023px) {
      max-width: 350px;
    }

    @media (max-width: 767px) {
      margin: 0 0 -15px 0;
    }

    p {
      text-align: right;
      margin: 14px 0;

      @media (max-width: 767px) {
        margin: 10px 0;
      }
    }
  }

  &__item {
    width: 100px;
    height: 100px;
    padding: 15px;
    position: absolute;
    transition: all 0.3s;
    border-radius: 1000px;

    @media (max-width: 1023px) {
      width: 95px;
      height: 95px;
    }

    @media (max-width: 767px) {
      padding: 8px;
      width: 60px;
      height: 60px;
    }

    &.active {
      background: #ffffff;
      border: 2px solid #366baa;
    }

    &:hover {
      cursor: pointer;
      background: #ffffff;
      border: 2px solid #366baa;
    }

    &:first-of-type {
      right: -18px;
      top: -35px;

      @media (max-width: 1023px) {
        right: -30px;
        top: -32px;
      }

      @media (max-width: 767px) {
        left: 0;
        top: 25px;
      }
    }

    &:nth-of-type(2) {
      top: 23px;
      right: 123px;

      @media (max-width: 1023px) {
        top: 20px;
        right: 90px;
      }

      @media (max-width: 767px) {
        top: 113px;
        right: 205px;
      }
    }

    &:nth-of-type(3) {
      right: 178px;
      top: 160px;

      @media (max-width: 1023px) {
        right: 138px;
        top: 140px;
      }

      @media (max-width: 767px) {
        right: 118px;
        top: 147px;
      }
    }

    &:nth-of-type(4) {
      right: 120px;
      top: 298px;

      @media (max-width: 1023px) {
        right: 90px;
        top: 263px;
      }

      @media (max-width: 767px) {
        right: 34px;
        top: 112px;
      }
    }

    &:nth-of-type(5) {
      top: 355px;
      right: -15px;

      @media (max-width: 1023px) {
        right: -30px;
        top: 310px;
      }

      @media (max-width: 767px) {
        right: -3px;
        top: 25px;
      }
    }
  }

  &__info {
    display: flex;
    flex-direction: column;

    max-width: 820px;
    width: 100%;
    margin-right: 30px;

    @media (max-width: 767px) {
      order: 2;
      margin-right: 0;
    }
  }

  &__inner {
    z-index: 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;

    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__desc {
    display: block;
    margin-left: auto;
    max-width: 590px;

    @media (max-width: 1349px) {
      margin-left: 0;
    }
    @media (max-width: 1023px) {
      max-width: 350px;
    }

    @media (max-width: 767px) {
      max-width: none;
    }
  }

  &__desc-item {
    text-align: left;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    color: #231f20;

    @media (max-width: 1023px) {
      margin-left: 0;
      font-size: 16px;
      line-height: 160%;
    }

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 160%;

      p {
        margin: 20px 0;
      }
    }

    &.inactive {
      display: none;
    }
    &.active {
      display: block;
    }
  }

  &__bubble {
    position: absolute;
    content: '';
    bottom: -800px;
    left: -200px;
    z-index: -1;
    transform: rotate(-90deg);
  }

  &__semi-circle {
    height: 389px;
    width: 194px;
    flex-shrink: 0;
    margin-right: 40px;
    margin-top: 15px;

    @media (max-width: 1023px) {
      width: 171.49px;
      height: 343.86px;
      margin-top: 20px;
      margin-right: 15px;
    }

    @media (max-width: 767px) {
      order: 1;
      display: flex;
      transform: rotate(-90deg);
      width: 121.15px;
      height: 242.92px;
      margin: 0;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}
