.Modal__bg{
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    background: #000000;
    opacity: 0.7;
    z-index: 3;
}


.Modal {
    padding: 0 20px 40px;
    overflow-x: hidden;
    overflow-y: overlay;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1200;
    padding: 0 20px;
    display: flex;

    @media (max-width: 767px) {
        padding: 0 15px;
    }
}

.Modal__wrapper {
    display: block;
    margin: auto;
    padding: 50px 0;
    z-index: 3;
    border-radius: 16px;
    max-width: 520px;

    @media (max-width: 767px) {
        width: 100%;
    }
}

.Modal__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px;
    border-radius: 16px;
    position: relative;
    max-width: 700px;
    background: #FFFFFF;
    background: #EEF0F5;

    @media (max-width: 767px) {
        max-width: none;
        padding: 23px;
    }

    &--success {
        padding: 40px 40px 20px 40px;

        @media (max-width: 767px) {
            padding: 25px 40px 0 40px;
        }
    }
}

.Modal__heading {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    text-align: center;
    text-transform: uppercase;
    color: #231F20;
    margin: 0 0 20px 0;

    @media (max-width: 767px) {
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        text-align: center;
        text-transform: uppercase;
        color: #231F20;
    }
}

.Modal__info {
    width: 100%;
    min-height: 128px;
    padding: 16px;

    background: #FFFFFF;
    border-radius: 8px;

    img {
        width: 64px;
        height: 48px;
    }
}


.Modal__info-title {
    margin-left: 10px;
}

.Modal__info-inner {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .Modal__info-title, .Modal__info-price {
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
    }

    .Modal__info-price {
        margin-left: auto;
    }
}

.Modal__counter {
    justify-content: flex-end;
    display: flex;
    align-items: center;
    margin-top: 10px;

    span {
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: #231F20;
        padding-left: 16px;
        padding-right: 16px;
    }
}


.Modal__minus {
    width: 32px;
    height: 32px;
    border: 1px solid #231F20;
    background-color: #FFFFFF;
    outline: none;

    svg {
        vertical-align: middle;
    }
}

.Modal__plus {
    width: 32px;
    height: 32px;
    border: 1px solid #231F20;
    background-color: #FFFFFF;
    outline: none;

    svg {
        vertical-align: middle;
    }
}

.Modal__close {
    background-color: transparent;
    outline: none;
    width: 15px;
    height: 15px;
    border: none;
    position: absolute;
    top: -35px;
    right: 17px;

    @media (max-width: 767px) {
        top: -36px;
        right: 20px;
    }
}

.Modal__form {
    width: 100%;
    margin-top: 10px;
}

.Modal__price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: right;
    color: #1C355E;
    text-transform: uppercase;
    margin-bottom: 10px;

    p {
        font-weight: 600;
        font-size: 14px;
        line-height: 160%;
    }

    span {
        font-weight: 600;
        font-size: 16px;
        line-height: 140%;
    }
}

.Modal__input {
    input {
        width: 100%;
        min-height: 44px;
        border: none;
        background: #FFFFFF;
        border-radius: 8px;
        margin-bottom: 8px;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        padding: 12px 16px;
        font-family: 'Montserrat', sans-serif!important;

        ::placeholder {
            font-family: 'Montserrat', sans-serif!important;
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            color: #231F20;
            opacity: 0.6;
        }
    }
}

.Modal__textarea {
    width: 100%;
    min-height: 44px;
    border: none;
    background: #FFFFFF;
    border-radius: 8px;
    margin-bottom: 16px;

    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    min-height: 120px;
    padding: 12px 16px;
    font-family: 'Montserrat', sans-serif!important;

    @media (max-width: 767px) {
        margin-bottom: 15px;
    }

    ::placeholder {
        font-family: 'Montserrat', sans-serif!important;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: #231F20;
        opacity: 0.6;
    }
}

.Modal__submit {
    background: #366BAA;
    border-radius: 100px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    border: none;
    padding: 15px 0;
    transition: all 0.3s;

    &:hover {
        background: #1C355E;
    }
}

.Modal__success {
    .Modal__text {
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        text-align: center;
        color: #231F20;

        @media (max-width: 767px) {
            font-weight: 400;
            font-size: 14px;
            line-height: 130%;
            margin: 20px 0;
            text-align: center;
        }
    }
}