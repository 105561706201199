.Registration {
    width: 100%;
  
    @media (max-width: 1149px) {
      margin-top: 16px;
    }
  
    &__wrapper {
      overflow: hidden;
      align-items: center;
      background: #1C355E;
      border-radius: 24px;
      margin: 30px 50px;
  
      @media (max-width: 1023px) {
        border-radius: 16px;
        margin: 15px 40px;
      }
  
      @media (max-width: 767px) {
        margin: 15px;
      }
    }

    &__wrap {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 18.5%;
      max-width: 620px;
      position: relative;
      z-index: 2;

      @media (max-width: 1023px) {
        margin-left: 14.5%;
      }

      @media (max-width: 767px) {
        margin-left: 22%;
      }

      .Button--round {
        position: relative;
        margin-top: 16px;
        border: 2px solid #ffffff;
        color: white;
        left: 0;
        bottom: 0;
        text-decoration: none;
        position: relative;
        transition: all 0.3s;

        &:hover {
          color: #1C355E;
          background: white;
          
        }

        p {
          max-width: 120px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }

        @media (max-width: 1023px) {
          margin-top: 17px;
        }

        @media (max-width: 767px) {
          margin-top: 0;
        }
      }
    }
  
    &__text {
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 160%;
      color: #ffffff;
      text-transform: initial;
      text-align: left;
      margin: 25px 0;

      @media (max-width: 1023px) {
        font-weight: 400;
        font-size: 16px;
        line-height: 160%;
      }

      @media (max-width: 767px) {
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        margin-top: 3px;
      }
    }
  
    &__inner {
      position: relative;
      padding: 60px 0 60px;

      @media (max-width: 1023px) {
        padding: 50px 0 50px;
      }
    }
    
  
    &__bubble-one {
      position: absolute;
      right: -200px;
      top: -265px;
      width: 710px;
      height: 891px;
      transform: rotate(40deg);
      transition: all 0.3s;

      @media (max-width: 1023px) {
        right: -300px;
        top: -300px;    
      }

      @media (max-width: 767px) {
        width: 252px;
        height: 316px;
        right: -100px;
        top: -70px;
      }
    }
  
    &__bubble-two {
      position: absolute;
      left: -180px; 
      bottom: -80px;
      width: 590px;
      height: 574px;
      transition: all 0.3s;

      @media (max-width: 1023px) {
        left: -300px;
        bottom: -300px;    
      }

      @media (max-width: 767px) {
        width: 330px;
        height: 330px;
        left: -80px;
        bottom: -120px;
      }
    }
  
    &__content {
      font-style: normal;
      font-weight: 600;
      font-size: 64px;
      line-height: 100%;
      text-transform: uppercase;
      color: #ffffff;
      margin: 0 0 16px;
      position: relative;
      z-index: 2;
  
      // @media (max-width: 1149px) {
      //   margin: 50px 0;
      //   flex-direction: column;
      // }
  
      // @media (max-width: 767px) {
      //   margin: 25px 0;
      // }

  
      h2 {
        text-transform: uppercase;
        color: #ffffff;
        text-align: left;
        margin: 0;

        b {
          @media (max-width: 499px) {
            padding-left: 22%;
            line-height: 37px;
          }
        }

        @media (max-width: 1199px) {
          font-weight: 600;
          font-size: 32px;
          line-height: 100%;
        }

        @media (max-width: 767px) {
          padding-left: 0;
          font-weight: 600;
          font-size: 24px;
          line-height: 100%;
        }
  
        p {
          padding-left: 18.5%;
          margin: 14px 0;
          font-weight: 600;
          font-size: 52px;
          line-height: 100%;
          white-space: nowrap;

          @media (max-width: 1199px) {
            font-weight: 600;
            font-size: 32px;
            line-height: 100%;

            padding-left: 15%;
            margin: 8px 0;
          }

          @media (max-width: 767px) {
            padding-left: 0;
            font-weight: 600;
            font-size: 24px;
            line-height: 31px;
            white-space: normal;
            margin: 0;
          }

          @media (max-width: 499px) {
            line-height: 31px;
            margin: 0;
          }
        }
      }
    }
  }
  