.About {
    padding: 100px 0;
    overflow: hidden;
    align-items: center;
    background: #eef0f5;
    border-radius: 24px;
    margin: 30px 40px;
    z-index: 0;

    @media (max-width: 1023px) {
        padding: 50px 0;
    }

    @media (max-width: 767px) {
        margin: 15px;
    }

    h2 {
        text-align: left;
        margin: 0;
    }

    &__desc {
        display: block;
        margin-left: 18.5%;
        max-width: 850px;
        text-align: left;
        margin-top: 40px;
        margin-bottom: 40px;
        font-weight: 400;
        font-size: 18px;
        line-height: 160%;
        transition: all 0.3s;
        color: #231F20;
    
       
        @media (max-width: 1023px) {
            margin-left: 14.5%;
            font-weight: 400;
            font-size: 16px;
            line-height: 160%;
        }
    
        @media (max-width: 767px) {
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            margin-left: 21%;
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }

    &__manufacturer {
        font-weight: 400;
        font-size: 18px;
        line-height: 160%;
        max-width: 620px;
        margin-right: auto;
        text-align: left;
        margin-top: 35px;
        transition: all 0.3s;

        @media (max-width: 1023px) {
            font-weight: 400;
            font-size: 16px;
            line-height: 160%;
            margin-left: 14.5%;
        }

        @media (max-width: 767px) {
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            margin-left: 21%;
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }

    &__manufacturer-bold {
        font-weight: 600;
        font-size: 32px;
        line-height: 130%;
        text-transform: uppercase;
        color: #231F20;
        text-align: left;
        transition: all 0.3s;
        margin: 40px 0 40px 18.5%;

        @media (max-width: 1023px) {
            margin: 40px 0 40px 0;
            font-weight: 600;
            font-size: 24px;
            line-height: 130%;
        }

        @media (max-width: 767px) {
            font-weight: 600;
            font-size: 18px;
            line-height: 140%;
            margin-top: 24px;
            margin-bottom: 24px;
        }
    }

    &__picture {
        width: 100%;
        max-width: 100%;
        height: auto;
        border-radius: 16px;
    }

    &__info {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media (max-width: 1023px) {
            flex-direction: column;
        }
        

        .About__info-picture {
            width: 48%;
            height: 240px;

            img {
                border-radius: 16px;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            @media (max-width: 1023px) {
                width: 100%;
                height: 240px;
            }

            @media (max-width: 767px) {
                height: 200px;
            }

            @media (max-width: 399px) {
                height: 118px;
            }
        }

    
        p {
            font-weight: 400;
            font-size: 18px;
            line-height: 160%;
            margin-left: 3%;
            margin-top: 0;
            text-align: left;
            width: 49%;

            @media (max-width: 1023px) {
                font-weight: 400;
                font-size: 16px;
                line-height: 160%;
                margin-left: 14.5%;
                width: auto;
                margin-top: 40px;
            }
    
            @media (max-width: 767px) {
                font-weight: 400;
                font-size: 14px;
                line-height: 160%;
                margin-left: 21%;
                margin-top: 24px;
            }
        }
    }
}