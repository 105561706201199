.Header {
  width: 100%;
  overflow: hidden;

  &__wrapper {
    overflow: hidden;
    align-items: center;
    background: #eef0f5;
    border-radius: 24px;
    margin: 30px 50px;

    @media (max-width: 1023px) {
      border-radius: 16px;
      margin: 15px 40px;
      max-height: 486px;
    }

    @media (max-width: 767px) {
      margin: 15px;
    }
  }

  &__text {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    color: #231f20;
    text-transform: initial;
    padding-left: 26%;
    text-align: left;
    margin: 25px 0;

    @media (max-width: 1349px) {
      padding-left: 30%;
    }

    @media (max-width: 1149px) {
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;

      padding-left: 14%;
      text-align: left;
      margin: 20px 0;
    }

    @media (max-width: 767px) {
      padding-left: 0;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 160%;
      color: #231f20;
      margin: 20px 0;
    }
  }

  &__inner {
    position: relative;
  }

  &__logo {
    width: 135px;
    height: 25px;
    flex-shrink: 0;

    @media (max-width: 1149px) {
      width: 94px;
      height: 18px;
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    padding-top: 40px;

    @media (max-width: 1149px) {
      background: #eef0f5;
      border-radius: 16px;
      padding: 17px 20px;
    }
  }

  &__image {
    width: 30%;
    max-width: 380px;
    height: 298px;
    flex-shrink: 0;

    @media (max-width: 1149px) {
      width: 300px;
      height: 236px;
      margin-left: 55%;
    }

    @media (max-width: 767px) {
      margin-left: 0;
      margin-right: auto;
      width: 211px;
      height: 166px;
    }

    @media (max-width: 499px) {
      margin-left: -75px;
    }

    @media (max-width: 349px) {
      margin-left: -90px;
    }

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }

  &__bubble-one {
    position: absolute;
    right: -500px;
    transform: rotate(40deg);
    top: -200px;
    transition: all 0.3s;

    @media (max-width: 1023px) {
      width: 632.58px;
      height: 793.03px;
      right: -100px;
      transform: rotate(40deg);
      top: -50px;
    }

    @media (max-width: 767px) {
      width: 280.01px;
      height: 351.03px;
      right: -50px;
      top: 250px;
    }
  }

  &__bubble-two {
    position: absolute;
    position: absolute;
    left: -100px;
    top: -150px;
    transition: all 0.3s;

    @media (max-width: 1023px) {
      width: 484px;
      height: 512px;
      top: -300px;
    }

    @media (max-width: 767px) {
      width: 312px;
      height: 329px;
      top: -100px;
      left: -130px;
    }
  }

  &__content {
    font-style: normal;
    font-weight: 600;
    font-size: 64px;
    line-height: 100%;
    text-transform: uppercase;
    color: #231f20;
    display: flex;
    justify-content: space-between;
    margin: 73px 0;
    position: relative;

    @media (max-width: 1149px) {
      margin: 50px 0;
      flex-direction: column;
    }

    @media (max-width: 767px) {
      margin: 25px 0;
    }

    &__texts {
      width: 70%;

      @media (max-width: 1149px) {
        width: 100%;
      }
    }

    h1 {
      text-transform: uppercase;
      color: #231f20;
      text-align: left;
      margin: 0;

      p {
        padding-left: 26%;
        margin: 14px 0;
        max-width: 840px;
        font-weight: 600;
        font-size: 64px;
        line-height: 100%;

        @media (max-width: 349px) {
          font-size: 26px !important;
        }

        br {
          display: none;

          @media (max-width: 499px) {
            display: block;
          }
        }

        span {
          @media (max-width: 499px) {
            display: block;
            margin-left: -26%;
            margin-top: 8px;
          }
        }

        @media (max-width: 1349px) {
          font-size: 50px;
          padding-left: 30%;
        }

        @media (max-width: 1299px) {
          font-weight: 600;
          font-size: 44px;
          line-height: 100%;
          margin: 10px 0;
        }

        @media (max-width: 1149px) {
          padding-left: 14%;
          font-weight: 600;
          font-size: 44px;
          line-height: 100%;
          margin: 8px 0;
        }

        @media (max-width: 767px) {
          padding-left: 21%;
          font-style: normal;
          font-weight: 600;
          font-size: 30px;
          line-height: 100%;
          text-transform: uppercase;
          color: #231f20;
        }
      }
    }
  }

  ul {
    display: flex;
    justify-content: space-between;
    width: 100%;
    list-style: none;
    padding: 0;
    margin-left: 7.5%;
    max-width: 825px;
    margin-right: 5%;
    transition: all 0.3s;

    @media (max-width: 1299px) {
      margin-left: 2%;
      margin-right: 2%;
    }

    @media (max-width: 1149px) {
      flex-direction: column;
      align-items: flex-end;
      width: 100%;
      background-color: #f5f5f5;
      position: fixed;
      top: 84px;
      left: 0;
      z-index: 1000;
      visibility: hidden;
      opacity: 0;
      max-width: none;
      padding: 26px 60px 26px;
      margin: 0;

      @media (max-width: 767px) {
        padding: 26px 16px 26px;
      }

      li {
        padding: 0 0 24px 0;

        &:last-of-type {
          padding: 0 0 13px 0;
        }
      }

      &.active {
        visibility: visible;
        opacity: 1;
        display: flex;
      }
    }

    li {
      cursor: pointer;
      font-weight: 400;
      font-size: 14px;
      line-height: 100%;
      text-transform: uppercase;
      color: #231f20;
      transition: all 0.3s;

      &:hover a {
        color: #366BAA;
      }
    }
  }
}


.header-section {
  transition: background-color 0.6s;
  padding: 0;
  position: relative;
  z-index: 3;
  animation: 200ms ease-in-out 0s normal none 1 running fadeInDown;
  
  @media (max-width: 1149px) {
    background-color: #f5f5f5 !important;
    margin-left: -50px;
    margin-right: -50px;
    padding: 16px 60px 0;
  }

  @media (max-width: 767px) {
    padding: 16px 50px;
  }
}

.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  box-shadow: 0 2px 24px 0 rgb(0 0 0 / 15%);
  background-color: #f5f5f5 !important;
  animation: 200ms ease-in-out 0s normal none 1 running fadeInDown;
  padding-top: 0px;
  padding-bottom: 0px;
  padding: 0 90px;

  @media (max-width: 1149px) {
    padding: 16px 50px;
    margin-left: 0;
    margin-right: 0;
  }

  @media (max-width: 767px) {
    padding: 16px;
  }

  .Header__nav {
    display: flex;
    justify-content: space-between;
    position: relative;
    align-items: center;
    padding-top: 25px;
    padding-bottom: 25px;

    @media (max-width: 1149px) {
      padding: 18px;
    }
  

    @media (max-width: 767px) {
      padding: 18px;
    }
  }
}

.Header__bg{
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background: #000000;
  opacity: 0.7;
  z-index: 3;
}