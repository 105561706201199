.Footer {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;

    margin-bottom: 0;

    &__logo {
        @media (max-width: 767px) {
            img {
                width: 94px;
                height: 18px;
            }
        }
    }

    @media (max-width: 1099px) {
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
    }

    a {
        text-decoration: none;
        transition: all 0.3s;
        color: inherit;

        &:hover {
            color: #1C355E;
        }
    }

    &__wrapper {
        background: #EEF0F5;
        border-radius: 24px;
        margin: 30px 50px;

        @media (max-width: 1099px) {
            margin: 30px 20px; 
        }
    }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-height: 126px;
        transition: all 0.3s;

        @media (max-width: 1099px) {
            flex-wrap: wrap;
            padding: 50px 0;

            .Footer__block {
                text-align: left;
                width: 49%;
                margin-right: 2%;
                display: flex;
             

                p {
                    max-width: 245px;
                    margin: 0;
                }

                &:nth-child(1) {
                    order: 1;
                    margin-bottom: 10px;
                }

                &:nth-child(4) {
                    order: 4;
                    margin-right: 0;
                    justify-content: flex-end;
                }

                &:nth-child(3) {
                    order: 2;
                    margin-right: 0;
                    justify-content: flex-end;
                    margin-bottom: 10px;
                }

                &:nth-child(2) {
                    order: 3;
                }
            }
        }

        @media (max-width: 767px) {
            flex-direction: column;
            padding: 25px 0;

            .Footer__block {
                text-align: left;
                width: 100%;
                margin-right: 0;
                display: flex;
                margin-bottom: 24px!important;
             
                p {
                    max-width: 245px;
                }

                &:nth-child(1) {
                    order: 1;
                }

                &:nth-child(4) {
                    order: 4;
                    margin-right: 0;
                    justify-content: flex-start;
                    margin-bottom: 0!important;
                }

                &:nth-child(3) {
                    order: 3;
                    margin-right: 0;
                    justify-content: flex-start;
                    margin-bottom: 10px;
                }

                &:nth-child(2) {
                    order: 2;
                }
            }
        }
    }

    &__socials {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 19px;
    }

    &__social {
        &:hover {
            svg {
                fill: #366BAA;
            }
        }
        svg {
            transition: all 0.3s;
            vertical-align: middle;
            fill: #1C355E;
        }
    }
}