.Button {
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #366baa;
  color: #ffffff;
  outline: none;

  &--primary {
    padding: 0 32px;
    max-width: 143px;
    min-height: 44px;

    background: #366baa;
    border-radius: 100px;

    font-weight: 600;
    font-size: 14px;
    line-height: 100%;
    transition: all 0.3s;

    &:hover {
      background: #1C355E;
    }

    @media (max-width: 1149px) {
      font-weight: 600;
      font-size: 12px;
      line-height: 100%;
      padding: 0 23px;
      max-width: 143px;
      min-height: 36px;

      margin-left: auto;
      margin-right: 25px;
    }
  }

  &--round {
    position: absolute;
    content: '';
    width: 182px;
    height: 182px;
    left: 0;
    bottom: 30px;
    background-color: transparent;
    border: 2px solid #366baa;
    border-radius: 1000px;
    font-weight: 600;
    font-size: 18px;
    line-height: 160%;
    text-align: center;
    text-transform: uppercase;
    color: #366baa;
    transition: all 0.3s;

    &:hover {
      background: #366BAA;
      border: 2px solid #366BAA;
      color: white;
    }

    @media (max-width: 1149px) {
      left: 14%;
    }

    @media (max-width: 1023px) {
    }

    @media (max-width: 1023px) {
      font-weight: 600;
      font-size: 14px;
      line-height: 160%;
      text-align: center;
      text-transform: uppercase;
      width: 134px;
      height: 134px;

      left: 14%;
      bottom: 100px;
    }

    p {
      max-width: 102px;
      margin: 0 auto;
    }

    @media (max-width: 767px) {
      right: 14%;
      bottom: 20px;
      left: auto;
    }
    @media (max-width: 499px) {
      left: auto;
      bottom: 14px;
      right: 2%;
    }
  }

  &--burger {
    display: none;
    @media (max-width: 1149px) {
      min-height: auto;
      flex-shrink: 0;
      max-width: none;
      border: none;
      background: #ffffff;
      opacity: 0.8;
      border-radius: 8px;
      width: 36px;
      height: 36px;
      display: block;
      padding: 0;
      position: relative;

      &:after {
        position: absolute;
        content: '';
        width: 20px;
        height: 2px;
        left: 8px;
        top: 14px;
        background-color: #366baa;
        transition: all 0.3s;
      }

      &.active {
          &:after {
          top: 17px;
          background-color: #366baa;
          transform: rotate(45deg);
        }
      }

      &:before {
        position: absolute;
        content: '';
        width: 20px;
        height: 2px;
        left: 8px;
        top: 22px;
        background-color: #366baa;
        transition: all 0.3s;
      }

        &.active {
          &:before {
          top: 17px;
          background-color: #366baa;
          transform: rotate(-45deg);

        }
      }
    }
  }
}
