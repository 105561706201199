@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local(''),
    url('../fonts/montserrat-v25-latin_cyrillic-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/montserrat-v25-latin_cyrillic-regular.woff') format('woff'); /* Modern Browsers */
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local(''),
    url('../fonts/montserrat-v25-latin_cyrillic-500.woff2') format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/montserrat-v25-latin_cyrillic-500.woff') format('woff'); /* Modern Browsers */
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: local(''),
    url('../fonts/montserrat-v25-latin_cyrillic-600.woff2') format('woff2'),
    /* Super Modern Browsers */
      url('../fonts/montserrat-v25-latin_cyrillic-600.woff') format('woff'); /* Modern Browsers */
}

*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none !important;
}

body {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: #F5F5F5;
}

button,
p,
span,
h3,
h4,
h5 {
  font-family: 'Montserrat', sans-serif;
}

h1 {
  font-family: 'Montserrat', sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 64px;
  line-height: 100%;

  @media (max-width: 1349px) {
    font-weight: 600;
    font-size: 50px;
    line-height: 100%;
  }

  @media (max-width: 1149px) {
    font-weight: 600;
    font-size: 44px;
    line-height: 100%;
  }

  @media (max-width: 767px) {
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 100%;
    text-transform: uppercase;
    color: #231f20;
  }

  @media (max-width: 349px) {
    font-size: 26px;
  }
}

h2 {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 52px;
  line-height: 100%;
  text-transform: uppercase;
  color: #231f20;
  margin: 0 0 60px 0;

  @media (max-width: 1023px) {
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 100%;
    text-transform: uppercase;
    color: #231f20;
    margin: 0 0 50px 0;
  }

  @media (max-width: 767px) {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    margin: 0 0 25px 0;
  }
}

img {
  max-width: 100%;
  height: auto;
}

a,
button {
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.App {
  text-align: center;
}

section {
  margin: 100px 0;

  @media (max-width: 1023px) {
    margin: 50px 0;
  }
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@import './header.scss';
@import './container.scss';
@import './button.scss';
@import './round-button.scss';
@import './advantages.scss';
@import './components.scss';
@import './composition.scss';
@import './tabs.scss';
@import './about.scss';
@import './modal.scss';
@import './products.scss';
@import './registration.scss';
@import './footer.scss';