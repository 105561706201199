.Container {
  max-width: 1340px;
  margin: 0 auto;
  padding: 0 40px;

  @media (max-width: 767px) {
    padding: 0 15px;
  }

  &__inner {
    max-width: 1440px;
    padding: 0 50px;
    margin: 0 auto;

    @media (max-width: 1023px) {
      padding: 0 40px;
    }

    @media (max-width: 767px) {
      padding: 0 15px;
    }
  }
}
