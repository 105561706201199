.Advantages {
  .Container {
    max-width: 1440px;
  }

  &__items {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    @media (max-width: 767px) {
      flex-wrap: nowrap;
      -ms-overflow-style: none; /* for Internet Explorer, Edge */
      scrollbar-width: none; /* for Firefox */
      overflow-y: scroll;

      margin-left: -15px;
      padding-left: 15px;
      margin-right: -15px;
      padding-right: 15px;
      &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
      }
    }
  }

  &__item {
    width: 31%;
    margin-right: 3.5%;
    margin-bottom: 80px;
    flex-shrink: 0;

    @media (min-width: 1024px) {
      &:nth-of-type(3n) {
        margin-right: 0;
      }

      &:nth-of-type(4),
      &:nth-of-type(5),
      &:nth-of-type(6) {
        margin-bottom: 0;
      }
    }

    @media (max-width: 1023px) {
      width: 48%;
      margin-right: 4%;
      margin-bottom: 40px;

      &:nth-of-type(2n) {
        margin-right: 0;
      }

      &:nth-of-type(5),
      &:nth-of-type(6) {
        margin-bottom: 0;
      }
    }

    @media (max-width: 767px) {
      width: auto;
      margin-bottom: 0;
    }
  }

  &__content {
    display: flex;
    align-items: flex-start;
  }

  &__texts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 0 20px;
    max-width: 315px;

    @media (max-width: 767px) {
      max-width: 204px;
    }

    p {
      margin: 0 0 10px 0;
      font-weight: 600;
      font-size: 18px;
      line-height: 140%;
      text-transform: uppercase;
      color: #231f20;
      text-align: left;
      max-width: 255px;

      @media (max-width: 767px) {
        font-weight: 600;
        font-size: 14px;
        line-height: 140%;
        max-width: 200px;
      }
    }

    span {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 140%;
      color: #666666;
      text-align: left;

      @media (max-width: 767px) {
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;
      }
    }
  }

  &__title {
    text-align: left;
  }
}
